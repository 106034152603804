<template>
    <layout :classes="classes" :routeName="routeName">
        <div :class="['row', { 'choose': state === 'choose' }]">
            <div class="left">
                <ChooseForm v-if="state === 'choose'" @choice="update($event)"></ChooseForm>
                <SignUpForm v-else @back="back()" :choice="choice"></SignUpForm>
            </div>

            <div class="right" v-if="state === 'signup'">
                <div class="content responsive desktop" v-if="choice === 'work'">
                    <h1 class="heading" v-html="$t('login.dispatch.work.title')"></h1>
                    <img class="hands-phone" src="../../../assets/images/hands-phone.svg" alt="hand phone background">
                </div>

                <div class="content  responsive desktop" v-if="choice === 'hire'">
                    <h1 class="heading" v-html="$t('login.dispatch.hire.title')"></h1>
                    <img class="hire-vector" src="../../../assets/images/vectors/hire-vector.svg" alt="hand phone background">
                </div>
            </div>
        </div>

        <div class="lb-bg"></div>
        
        <img v-if="$screen.width <= 576" class="bottom-bg mobile" src="../../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
        <img v-else-if="$screen.width <= 768" class="bottom-bg tablet" src="../../../assets/images/backgrounds/bg_auth_tablet.svg" alt="abstract background">
        <img v-else class="bottom-bg desktop" src="../../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
    </layout>
</template>

<script>
import Layout from '../../../components/Layout.vue';
import SignUpForm from './SignUpForm.vue'
import ChooseForm from './ChooseForm.vue';

export default {
    name: "signup",

    components: {
        Layout,
        SignUpForm,
        ChooseForm
    },

    props: {
        user_type: {
            type: String,
            default: 'general' 
        },
    },

    data() {
        return {
            routeName: "signup",
            state: 'choose',
            choice: '',
            classes: {
                signup: true
            },
        }
    },

    methods: {
        update(choice) {
            const self = this;

            self.choice = choice;
            self.state = 'signup';
        },

        back() {
            const self = this;

            if(self.$screen.width <= 768) {
                if(self.$route.query && self.$route.query.state && self.$route.query.choice) {
                    self.$router.push({ name: 'login' });
                }
            }

            self.state = 'choose';
            self.choice = '';
        }
    },

    mounted() {
        const self = this;

        if(self.$route.query) {
            if(self.$route.query.state && self.$route.query.choice) {
                self.state = self.$route.query.state;
                self.choice = self.$route.query.choice;
            }
        }
    }
};
</script>