<template>
    <div class="content">
        <div class="form-container choose">
            <div class="title-container">
                <h3 class="heading small">{{$t('signup.choose.title')}}</h3>
            </div>

            <div class="form">
                <div class="form-content" v-match-height="{el: '.vector-img'}">
                    <span id="sign-up-choice-work" class="button-with-image" @click="$emit('choice', 'work')">
                        <img class="vector-img" src="../../../assets/images/vectors/work-vector.svg" alt="work vector" loading="eager">
                        <p>{{ $t('signup.choose.choices.work') }}</p>
                    </span>
                    
                    <span id="sign-up-choice-hire" class="button-with-image" @click="$emit('choice', 'hire')">
                        <img class="vector-img" src="../../../assets/images/vectors/hire-vector.svg" alt="hire vector" loading="eager">
                        <p>{{ $t('signup.choose.choices.hire') }}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChooseForm",
};
</script>