<template>
    <div class="content">
        <div class="form-container">
            <div class="title-container">
                <div class="cta cta-slim cta-outline light arrow" @click="$emit('back')"><font-awesome-icon :icon="['fa', 'arrow-left']" /></div>
                <h3 class="heading">{{$t('signup.title')}}</h3>
            </div>

            <div class="form signup">
                <div class="form-content">
                    <div class="input-container">
                        <label id="fname-label" :class="error('fname')">{{$t('signup.form.fname')}}</label>
                        <input id="fname-input" :class="error('fname')" v-model="data.fname" type="text" @keyup.enter="sign_up()"> 

                        <FormError :data="validation.fname"/>
                    </div>

                    <div class="input-container">
                        <label id="email-label" :class="error('email')">{{$t('signup.form.email')}}</label>
                        <input id="email-input" :class="error('email')" v-model="data.email" type="text" @keyup.enter="sign_up()"> 

                        <FormError :data="validation.email"/>
                    </div>

                    <div class="input-container">
                        <label id="postal-code-label" :class="error('postalCode')">{{$t('signup.form.postal_code')}}</label>
                        <the-mask id="postal-code-input" :class="['input input-text', error('postalCode')]" :mask="['A#A #A#']" v-model="data.postalCode" @keyup.native.enter="sign_up()" :placeholder="$t('authentication.verifyPostalCode.form.postalCode.placeholder')" />

                        <FormError :data="validation.postalCode"/>
                    </div>
                    
                    <div class="input-container hidden">
                        <label id="referred-label">{{$t('signup.form.referred_code')}}</label>
                        <input id="referred-input" v-model="data.referred_code" type="text" @keyup.enter="sign_up()"> 
                    </div>
                    
                    <div class="input-container">
                        <label id="password-label" :class="error('password')">{{$t('signup.form.password')}}</label>
                        <div class="password-container">
                            <input id="password-input" :class="error('password')" v-model="data.password" :type="pwd_field_type" @keyup.enter="sign_up()">
                            <span class="show-password cta cta-form dark" @click="change_password_field_type()"><font-awesome-icon :icon="['fa', pwd_field_type === 'password' ? 'eye' : 'eye-slash']" /></span>
                        </div>
                        
                        <FormError :data="validation.password" :classes="classes"/>
                    </div>

                    <vue-hcaptcha v-if="!recaptcha_valid" ref="recaptcha" size="normal" :language="currentLanguage" :sitekey="site_key" @verify="verify"></vue-hcaptcha>
                    <button id="sign-up" class="cta cta-primary submit" v-if="recaptcha_valid" :class="{ loading: loading }" @click="sign_up()">{{$t('signup.form.submit.label')}}</button>
                </div> 
            </div>
            
            <p class="terms" v-html="$t('signup.terms')"></p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: "SignUpForm",

    components: {
        VueHcaptcha,
        FormError
    },

    props: {
        choice: {
            type: String,
            default: 'none' 
        },
    },
    
    data() {
        return {
            data: {
                fname: '',   
                email: '',
                referred_code: '',
                password: '',
                postalCode: ''
            },

            validation: {
                fname: {
                    error: false,
                    name: 'email',
                    key: 'errors.validation.fieldMandatory'
                },
                email: {
                    error: false,
                    name: 'email',
                    key: 'errors.validation.emailInvalid'
                },
                password: {
                    error: false,
                    name: 'password',
                    key: 'errors.validation.passwordInvalid'
                },
                postalCode: {
                    error: false,
                    name: 'postal-code',
                    key: 'errors.validation.postalCodeFormatInvalid'
                },
            },

            formValidated: false,
            loading: false,

            site_key: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
            pwd_field_type: 'password',   
            recaptcha_valid: false,

            //eslint-disable-next-line
            emailRegex:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            postal_code_regex: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,

            classes: [{ 'nomargin-top': true }]
        }
    },

    watch: {
        referred_code: function() {
            const self = this;
            if(self.referred_code) { self.data.referred_code = self.referred_code }
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage',
            'referred_code',
            'website_email',
            'ref_partner',

            'utm_source',
            'utm_medium',
            'utm_content',
            'utm_term',
            'utm_campaign',
            'native_ios'
        ])
    },

    methods: {
        ...mapActions([
            'setToken',
            'reset_onboarding',
            'delete_referred_code',
            'delete_website_email',
            'delete_ref_partner',
            'fetch_website_email',
            'fetch_referred_code',
            'fetch_ref_partner'
        ]), 

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
                
                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(!self.emailRegex.test(self.data.email))
                self.validation.email.error = true;

            if(!self.postal_code_regex.test(self.data.postalCode.trim())) {
                self.validation.postalCode.error = true;
            }

            if(self.data.password.length < 10 )
                self.validation.password.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        sign_up: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated && self.recaptcha_valid) {
                self.loading = true;

                const data = {
                    email: self.data.email,
                    fname: self.data.fname,
                    referred_code: self.data.referred_code,
                    password: self.data.password,
                    postalCode: self.data.postalCode,
                    preferredCommunicationLanguage: self.currentLanguage,
                    sign_up_choice: self.choice,
                    utms: {
                        utm_source: self.utm_source ?? 'none',
                        utm_medium: self.utm_medium ?? 'none',
                        utm_content: self.utm_content ?? 'none',
                        utm_term: self.utm_term ?? 'none',
                        utm_campaign: self.utm_campaign ?? 'none',
                    }
                };
                
                if(self.ref_partner) {
                    data.ref_partner = self.ref_partner.trim();
                }
                
                self.$axios
                    .post('/signup', data)
                    .then(response => {
                        self.loading = false;
                        
                        if(response.status == 200) {
                            self.setToken(response.data);
                            self.delete_referred_code();
                            self.delete_ref_partner();
                            self.delete_website_email();
                            self.reset_onboarding();

                            if(self.choice) {
                                self.$utils._navigate_to_name_with_query('get-started', { choice: self.choice });
                            }else {
                                self.$utils._navigate_to_name('get-started');
                            }
                        }
                    })
                    .catch(error => {
                        self.loading = false;
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        },

        change_password_field_type: function() {
            const self = this;
            self.pwd_field_type = self.pwd_field_type === 'password' ? 'text' : 'password'
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        verify: function() {
            const self = this;
            self.recaptcha_valid = true;
        }
    },

    mounted() {
        const self = this;

        self.fetch_website_email();
        self.fetch_referred_code();
        self.fetch_ref_partner();
        
        if(self.referred_code) { self.data.referred_code = self.referred_code }
        if(self.website_email) { self.data.email = self.website_email }
        
        self.$nextTick(() => {
            if(process.env.VUE_APP_ENV === 'local') { self.recaptcha_valid = true; }
            if(document && document.body && document.body.classList) {
                if(self.native_ios || document.body.classList.contains('is_standalone_ios') || document.body.classList.contains('is_standalone_android')) {
                    self.recaptcha_valid = true;
                }
            }
        });
    }
};
</script>